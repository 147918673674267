import 'moment/locale/ru';
import moment from 'moment/min/moment-with-locales.js';

export default function useMomentDate() {
    moment.locale('ru');
    const latest = (iso: string) => {
        const date = moment(iso).utcOffset(3);
        if (date.isSame(moment(), 'hour')) {
            return date.fromNow();
        }
        if (date.isSame(moment(), 'day')) {
            return date.fromNow();
            //return `сегодня в ${date.format('HH:mm')}`;
        }
        if (date.isSame(moment(), 'month')) {
            return date.format('DD MMMM HH:mm');
        }
        if (date.isSame(moment(), 'year')) {
            return date.format('DD MMMM HH:mm');
        }
        return date.format('DD MMMM YYYY HH:mm');
    };
    const fromNow = (iso: string) => {
        const date = moment(iso).utcOffset(3);
        if (date.isSame(moment(), 'hour')) {
            return date.fromNow();
        }
        if (date.isSame(moment(), 'day')) {
            return date.fromNow();
            //return `сегодня в ${date.format('HH:mm')}`;
        }
        if (date.isSame(moment(), 'month')) {
            return date.fromNow();
        }
        if (date.isSame(moment(), 'year')) {
            return date.fromNow();
        }
        return date.format('DD MMMM YYYY HH:mm');
    };
    const latestDate = (iso: string) => {
        const date = moment(iso).utcOffset(3);
        if (date.isSame(moment(), 'hour')) {
            return date.fromNow();
        }
        if (date.isSame(moment(), 'day')) {
            return date.fromNow();
            //return `сегодня в ${date.format('HH:mm')}`;
        }
        if (date.isSame(moment(), 'month')) {
            return date.format('DD MMMM');
        }
        if (date.isSame(moment(), 'month')) {
            return date.format('DD MMMM');
        }
        if (date.isSame(moment(), 'year')) {
            return date.format('DD MMMM');
        }
        return date.format('DD MMMM YYYY');
    };
    const dateTime = (iso: string) => {
        const date = moment(iso);
        return date.format('DD.MM.YYYY HH:mm');
    };
    const hhmmss = (val) => {
        const sec_num = parseInt(val, 10); // don't forget the second param
        let hours = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        let seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) {
            hours = '0' + hours;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        if (seconds < 10) {
            seconds = '0' + seconds;
        }
        return hours + ':' + minutes + ':' + seconds;
    };
    return {
        latest,
        fromNow,
        latestDate,
        dateTime,
        hhmmss,
    };
}
